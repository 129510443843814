.wrapper {
  position: absolute;
  user-select: none;
}

.move {
  position: absolute;
  border: 1px solid white;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
}
