.react-toggle {
  margin-left: 20px;
  margin-right: 20px;
}

.mainLogo {
  width: 120px;
  margin-left: 00px;
}

.bx--toggle__label {
  margin-top: -12px;
  color: var(--secondaryText);
}
.bx--toggle__text--left {
  color: var(--secondaryText);
}
.bx--toggle__text--right {
  color: var(--secondaryText);
}

.bx--form-item {
  margin-top: 20px;
  margin-bottom: 20px;
}

.bx--text-input:focus {
  outline: none;
  box-shadow: 0 0px 0 0 #3d70b2 !important;
}

.bx--text-input {
  outline: none;
  box-shadow: 0 0px 0 0 #3d70b2 !important;
}

.bx--tooltip--icon__top path,
.bx--tooltip--icon__bottom path {
  fill: var(--form-text);
}
.bx--tooltip--icon__top:hover path,
.bx--tooltip--icon__top:focus path,
.bx--tooltip--icon__bottom:hover path,
.bx--tooltip--icon__bottom:focus path {
  fill: var(--form-text);
}
.bx--tooltip--icon__top polygon,
.bx--tooltip--icon__bottom polygon {
  fill: var(--form-text);
}
.bx--tooltip--icon__top:hover polygon,
.bx--tooltip--icon__top:focus polygon,
.bx--tooltip--icon__bottom:hover polygon,
.bx--tooltip--icon__bottom:focus polygon {
  fill: var(--form-text);
}
.bx--tooltip--icon__top rect,
.bx--tooltip--icon__bottom rect {
  fill: var(--form-text);
}
.bx--tooltip--icon__top:hover rect,
.bx--tooltip--icon__top:focus rect,
.bx--tooltip--icon__bottom:hover rect,
.bx--tooltip--icon__bottom:focus rect {
  fill: var(--form-text);
}

.bx--list-box__label {
  /*color: var(--secondaryText) !important;*/
  font-weight: normal;
}

.bx--list-box__menu-icon > svg {
  fill: var(--secondaryText) !important;
  height: 100%;
}

.bx--list-box__menu-item {
  background-color: var(--textInput) !important;
  color: var(--secondaryText) !important;
}

.video-modal-div .bx--form-item {
  width: auto;
  display: inline-block;
  margin-top: 5px;
  margin-bottom: 5px;
}

#run-name {
  background-color: var(--secondaryBg) !important;
}

.bx--text-input {
  box-shadow: 0 0px 0 0 #fff;
  color: var(--brightText) !important;
  background-color: var(--bg) !important;
  border-bottom: 1px solid var(--detailText);
}

.bx--text-area {
  color: #f4f4f4 !important;
  box-shadow: 0 0px 0 0 #fff;
  background-color: #262626 !important;
  border-bottom: 1px solid #6f6f6f;
}
.bx--time-picker__input-field {
  color: #f4f4f4 !important;
  box-shadow: 0 0px 0 0 #fff;
  background-color: #262626 !important;
  border-bottom: 1px solid #6f6f6f;
}

.bx--date-picker__input {
  color: #f4f4f4 !important;
  box-shadow: 0 0px 0 0 #fff;
  background-color: #262626 !important;
  border-bottom: 1px solid #6f6f6f;
}

.bx--list-box__menu-item--highlighted {
}

.bx--modal-container {
  width: 100%;
  background-color: #111417;
}
.bx--modal-footer {
  background-color: #111417;
  padding-top: 0px;
  margin-top: -20px;
}
.bx--modal-header {
  margin-bottom: 5px;
}
.bx--modal-header__heading {
  color: #f4f4f4;
}
.bx--modal {
  pointer-events: none;
}

.bx--data-table-v2 th:hover .bx--table-sort-v2__icon {
  opacity: 0.5;
}

.bx--table-sort-v2--active .bx--table-sort-v2__icon {
  opacity: 1 !important;
}

/* Table */

.bx--data-table-v2-container {
  padding-top: 0px;
}

.bx--data-table-v2 tbody {
  background-color: var(--secondaryBg);
}

.bx--data-table-v2 th:last-of-type,
.bx--data-table-v2 td:last-of-type {
  border-right: 1px solid var(--border) !important;
}

.bx--data-table-v2 th,
.bx--data-table-v2 td {
  border-top: 1px solid var(--highlight) !important;
}

.bx--data-table-v2 th:first-of-type,
.bx--data-table-v2 td:first-of-type {
  border-left: 1px solid var(--border) !important;
}

.bx--data-table-v2 th,
.bx--data-table-v2 td {
  color: var(--secondaryText) !important;
}

.bx--data-table-v2 {
  border-bottom: 1px solid var(--border) !important;
}

.bx--data-table-v2 thead th {
  border-top: 1px solid var(--border) !important;
  border-bottom: 1px solid var(--appBarShadow) !important;
}

/* Table hover item */

.bx--data-table-v2 tr:hover td {
  color: var(--brightText) !important;
  background-color: var(--highlight) !important;
  border-top: 1px solid transparent !important;
  border-bottom: 1px solid transparent !important;
}

.bx--data-table-v2 tr:last-of-type:hover td {
  border-bottom: 1px solid var(--border) !important;
}

.bx--data-table-v2 tr:hover td:first-of-type {
  border-left: 1px solid var(--border) !important;
}

.bx--data-table-v2 tr:hover td:last-of-type {
  border-right: 1px solid var(--border) !important;
}

.bx--data-table-v2 thead {
  background-color: var(--appBar);
}

.bx--data-table-v2.bx--skeleton th span,
.bx--data-table-v2.bx--skeleton td span {
  background: var(--skeleton);
}

.bx--data-table-v2.bx--skeleton th span:before,
.bx--data-table-v2.bx--skeleton td span:before {
  background: var(--skeleton);
}

.bx--table-sort-v2 {
  color: var(--brightText) !important;
}

.bx--table-sort-v2--active .bx--table-sort-v2__icon {
  opacity: 1 !important;
}

.bx--table-sort-v2:focus svg {
  opacity: 0;
  outline: none;
  fill: var(--blue);
}

/* Select input */

.bx--select {
  width: 100%;
}

.bx--select-input {
  background-color: #0b0804;
  color: var(--brightText);
  box-shadow: 0 1px 0 0 var(--textInputUnderline);
}

.bx--select-input:focus {
  box-shadow: 0 0 0 2px var(--blue);
}

.bx--select__arrow {
  fill: var(--brightText);
}

.bx--text-input[data-invalid='true'] {
  animation-name: autofillFocus;
  animation-fill-mode: both;
}

@keyframes autofillFocus {
  to {
    outline: none;
    background-color: var(--textInput);
    color: var(--brightText);
    box-shadow: 0 0 0 2px var(--error);
  }
}

/* Link */

/* a,
a:visited {
  color: var(--blue);
  text-decoration: none;
}

a:hover {
  box-shadow: 0 1px var(--blue);
}

a:active {
  color: var(--brightText);
}

a:focus {
  color: var(--brightText);
  box-shadow: 0 3px var(--brightText);
} */

.bx--toggle__label {
  color: #5a6872 !important;
}
.bx--toggle__text--left {
  color: #5a6872 !important;
  font-size: 12px;
}
.bx--toggle__text--right {
  color: #5a6872 !important;
  font-size: 12px;
}
.bx--data-table-v2-container {
  overflow-x: hidden;
}

.bx--checkbox-label::before {
  height: 30px;
  width: 30px;
}

.bx--checkbox-label::after {
  zoom: 1.8;
  margin-top: -1px;
  left: 4px;
}

.bx--checkbox-wrapper {
  display: inline-block;
  margin-left: 15px;
  top: -4px;
  position: relative;
}

.bx--checkbox-label span {
  margin-left: 20px;
  margin-top: 10px;
}

.bx--modal-footer {
  z-index: 10000;
}

.bx--date-picker__icon {
  display: none;
}

.bx--date-picker__icon ~ .bx--date-picker__input {
  padding-left: 2rem;
}

.bx--select__arrow {
  display: none;
}

.bx--label {
  color: var(--brightText);
  z-index: 10000;
  height: 50px;
  width: 80%;
  position: absolute;
  margin-top: -20px;
}

.bx--select-input {
  z-index: 30000;
}

#data-settings-modal .bx--modal-container {
  max-width: 100% !important;
  max-height: 100% !important;
  width: 100% !important;
  height: 100% !important;
}

#data-settings-modal
  > div
  > div.bx--modal-footer
  > button.bx--btn.bx--btn--secondary {
  display: none;
}

#data-settings-modal > div > div.bx--modal-content {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

#data-settings-modal > div > div.bx--modal-content::-webkit-scrollbar {
  display: none;
}

#data-settings-modal
  > div
  > div.bx--modal-content
  > span
  > div
  > span
  > div.bx--form-item.bx--checkbox-wrapper
  > label::before {
  height: 15px;
  width: 15px;
  margin-top: 7px;
  margin-left: 12px;
}

#data-settings-modal
  > div
  > div.bx--modal-content
  > span
  > div
  > span
  > div.bx--form-item.bx--checkbox-wrapper
  > label::after {
  zoom: 1.3;
  margin-top: 0px;
  left: 11px;
}

#input-modal > div > div.bx--modal-footer > button.bx--btn.bx--btn--secondary {
  display: none;
}

#upload-modal
  > div
  > div.bx--modal-footer
  > button.bx--btn.bx--btn--primary.bx--btn--disabled {
  display: none;
}

.mobile .bx--modal-container {
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  background-color: white;
  border: 0px;
}

.mobile .bx--modal-container .bx--modal-footer {
  background-color: white;
}

.mobile .bx--modal-container .bx--modal-header__heading {
  color: black;
}

h1,
.bx--type-alpha {
  margin-top: 25px;
  margin-bottom: 15px;
}
h2,
.bx--type-alpha {
  margin-top: 15px;
  margin-bottom: 10px;
}
h3,
.bx--type-alpha {
  margin-top: 15px;
  margin-bottom: 10px;
}
p,
.bx--type-alpha {
  margin-top: 5px;
  margin-bottom: 5px;
}
li,
.bx--type-alpha {
  margin-top: 5px;
  margin-bottom: 5px;
}

.mobile .bx--text-input {
  background-color: #f4f7fb !important;
  color: black !important;
}

.mobile > div > div.bx--modal-content > div {
  color: black !important;
}

.mobile-hide > div > div.bx--modal-footer > button.bx--btn.bx--btn--primary {
  display: none !important;
}

.mobile > div > div.bx--modal-content > div > div:nth-child(2) {
  color: rgb(119, 119, 119) !important;
}

.bx--modal-content {
  color: rgb(119, 119, 119) !important;
}

input {
  caret-color: var(--form-text) !important;
}
textarea {
  caret-color: var(--form-text) !important;
}
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--textInputUnderline) !important;
}

.newlink {
  padding-top: 0.18rem !important;
  width: 80% !important;
  margin-left: -60px !important;
  font-size: 16px !important;
  font-weight: bold !important;
}

.newitem {
  width: 25% !important;
}

@media (min-width: 600px) {
  .newitem {
    width: 100% !important;
    margin-left: 0px !important;
  }
}

@media (max-width: 992px) {
  .newitem {
    width: 100% !important;
    margin-left: 0px !important;
  }
  #navbarContent {
    z-index: -1;
  }
}

.bx--list-box__menu {
  max-height: 300px !important;
}
