.wrapper {
  position: absolute;
  user-select: none;
}

.label {
  position: absolute;
  background-color: var(--blue);
  font-weight: 500;
  font-size: 12px;
  color: #ffffff;
  padding: 0 6px;
  left: -3px;
  top: -24px;
  height: 18px;
  line-height: 14px;
  border: 1px solid black;
}
