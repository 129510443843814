.wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  height: 80%;
}

.canvasBase {
  /* important for safari */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.blendMode {
  composes: canvasBase;
  mix-blend-mode: difference;
}

.normalMode {
  composes: canvasBase;
}

.image {
  user-select: none;
  max-width: 100%;
  max-height: 100%;
}
