#upload-modal .bx--modal-container {
  width: 80%;
  max-width: 80%;
  height: 85%;
}
#upload-modal-api .bx--modal-container {
  width: 80%;
  max-width: 80%;
  height: 85%;
}

#deckgl-wrapper {
  position: relative !important;
}

.bx--modal-content {
  height: 100%;
  width: 100%;
  margin-bottom: 0px;
}

.bx--label {
  color: var(--brightText);
}

.bx--slider__range-label {
  color: var(--secondaryText);
}

.bx--modal-close {
  display: none !important;
}
.bx--modal-container {
  width: 100%;
  pointer-events: all;
}
.bx--text-input {
  box-shadow: 0 1px 0 0 #fff;
}
.bx--loading__svg {
  stroke: #3d70b2;
}

.error-login {
  color: red;
  font-style: italic;
  font-size: 14px;
  margin-top: -10px;
  margin-bottom: 10px;
  text-transform: capitalize;
}

.swal-overlay {
  background-color: transparent !important;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  overflow: hidden;
  font-family: 'Atlas-Grotesk Regular', 'ibm-plex-sans', Helvetica Neue, Arial,
    sans-serif;
  /* text-rendering: optimizelegibility; */
  background-color: var(--bg);
  color: var(--brightText);
  --error: #da1e28;
  --blue: #0062ff;
  --blue-hover: #0353e9;
  --font-size-14: 14px;
}

body.dark {
  --selectionBar: #393939;
  --selectionBarHover: #4c4c4c;
  --selectionBarBorder: transparent;
  --selectionBarOutline: #ffffff;

  --primaryLink: #78a9ff;
  --primaryLinkHover: #a6c8ff;
  --primaryLinkHoverBG: #353535;

  --skeleton1: #353535;
  --skeleton2: #525252;

  --success: #42be65;
  --training: #f1c21b;
  --canceled: #6f6f6f;
  --failed: #fa4d56;

  --form-bg: #393939;
  --form-text: #f4f4f4;

  --select-icon: #f4f4f4;
  --popup-bg: #262626;
  --popup-overlay: rgba(38, 38, 38, 0.7);

  --dissabledButton: rgba(255, 255, 255, 0.12);
  --dissabledButtonText: rgba(255, 255, 255, 0.2);

  --secondaryButton: #6f6f6f;
  --secondaryButtonHover: #606060;

  --images: url('common/EmptySet/imagesdark.png');

  --dropzoneGrad1: hsla(0, 0%, 0%, 1);
  --dropzoneGrad2: hsla(0, 0%, 0%, 0.85);

  --skeleton: rgba(255, 255, 255, 0.1);

  --textInput: #282828;
  --textInputUnderline: #606060;

  --dropDownBorder: #414141;
  --listDivider: var(--dropDownBorder);

  --toolBarSpacer: #000000;

  --toolColor: #ffffff;
  --textInputBack: #21282a;
  --activeHighlight: var(--blue);
  --default-font-weight: normal;
  --bg: #111417;
  --border: transparent;
  --secondaryBg: #181c1e;
  --appBar: #1b1f21;
  --appBarShadow: var(--bg);
  --brightText: rgba(255, 255, 255, 1);
  --secondaryText: rgba(255, 255, 255, 0.87);
  --detailText: rgba(255, 255, 255, 0.53);
  --disabledText: rgba(255, 255, 255, 0.2);
  --ultraDim: rgba(255, 255, 255, 0.08);
  --highlight: #26292a;

  --progressBg: rgba(255, 255, 255, 0.53);
}

body.light {
  --selectionBar: white;
  --selectionBarHover: #e5e5e5;
  --selectionBarBorder: #dadce0;
  --selectionBarOutline: #0f62fe;

  --primaryLink: #0f62fe;
  --primaryLinkHover: #0043ce;
  --primaryLinkHoverBG: #e5e5e5;

  --skeleton1: #e5e5e5;
  --skeleton2: #c6c6c6;

  --success: #24a148;
  --training: #f1c21b;
  --canceled: #8d8d8d;
  --failed: #da1e28;

  --form-bg: #ffffff;
  --form-text: #161616;

  --select-icon: #161616;
  --popup-bg: #f4f4f4;
  --popup-overlay: rgba(22, 22, 22, 0.5);

  --dissabledButton: rgba(0, 0, 0, 0.12);
  --dissabledButtonText: rgba(0, 0, 0, 0.2);

  --secondaryButton: #3d3d3d;
  --secondaryButtonHover: #4c4c4c;

  --images: url('common/EmptySet/imageslight.png');

  --dropzoneGrad1: hsla(0, 0%, 100%, 1);
  --dropzoneGrad2: hsla(0, 0%, 100%, 0.85);

  --skeleton: rgba(0, 98, 255, 0.1);

  --textInput: #f3f3f3;
  --textInputUnderline: #a7a7a7;

  --toolBarSpacer: #dadce0;

  --dropDownBorder: transparent;
  --listDivider: #dadce0;

  --toolColor: var(--blue);
  --textInputBack: #eef4fc;
  --activeHighlight: var(--blue);
  --default-font-weight: 500;
  --border: var(--listDivider); /*rgb(217, 217, 217);*/
  --bg: #fff;
  --secondaryBg: white;
  --appBar: #ffffff;
  --appBarShadow: var(--border);
  /* --brightText: #152935; */
  /* --secondaryText: rgb(90, 104, 114); */
  /* --detailText: rgba(90, 104, 114, 0.5); */
  --brightText: rgba(0, 0, 0, 1);
  --secondaryText: rgba(0, 0, 0, 0.87);
  --detailText: rgba(0, 0, 0, 0.53);
  --disabledText: rgba(0, 0, 0, 0.2);
  --ultraDim: rgba(0, 0, 0, 0.08);
  --highlight: #eef4fc;

  --progressBg: rgb(224, 224, 224);
}

pre {
  color: var(--form-text);
  font-family: 'ibm-plex-mono', 'Lucida Console', Monaco, monospace;
  font-size: 95%;
  line-height: 1.45;
  overflow: auto;
  padding: 1rem;
  word-wrap: normal;

  border-radius: 0.4rem;
  background-color: var(--form-bg);
}

pre code {
  background-color: transparent;
  border: 0;
  display: inline;
  font-size: 100%;
  line-height: inherit;
  margin: 0;
  max-width: auto;
  overflow: visible;
  padding: 0;
  white-space: pre;
  word-break: normal;
  word-wrap: normal;
}

@keyframes skeleton {
  0% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3;
  }

  20% {
    width: 100%;
    left: 0;
    right: auto;
    opacity: 1;
  }

  28% {
    width: 100%;
    left: auto;
    right: 0;
  }

  51% {
    width: 0%;
    left: auto;
    right: 0;
  }

  58% {
    width: 0%;
    left: auto;
    right: 0;
  }

  82% {
    width: 100%;
    left: auto;
    right: 0;
  }

  83% {
    width: 100%;
    left: 0;
    right: auto;
  }

  96% {
    width: 0%;
    left: 0;
    right: auto;
  }

  100% {
    width: 0%;
    left: 0;
    right: auto;
    opacity: 0.3;
  }
}

* {
  box-sizing: border-box;
}
