.wrapper {
  position: absolute;
  user-select: none;
}

.nobBase {
  position: absolute;
  border: 1px solid white;
  width: 7px;
  height: 7px;
}

.topLeft {
  composes: nobBase;
  top: -3px;
  left: -3px;
}

.topRight {
  composes: nobBase;
  top: -3px;
  right: -3px;
}

.bottomRight {
  composes: nobBase;
  bottom: -3px;
  right: -3px;
}

.bottomLeft {
  composes: nobBase;
  bottom: -3px;
  left: -3px;
}
